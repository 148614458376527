/* eslint-disable camelcase */

import parse from 'html-react-parser'
import React, { useRef } from 'react'
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Modal,
  Row
} from 'react-bootstrap'
import arrowUpBoldSvg from '../../images/arrow-up-bold.svg'
import { usePublishAnswerToQuestion, useSellerDeleteQuestion } from '../../utils/api'
import { useGetListingQuery } from '../api'
import { CREATE_LISTING_TAB_PAGES } from '../editListingTabs/ListingPageIndexes'
import { vendorNavTo } from '../navigation'
import Spinner from '../spinner'
import Announcements from './communicationComponents/Announcements'
import NumberedRoundBage from './communicationComponents/NumberedRoundBage'
import QuestionAnswerCard from './communicationComponents/QuestionAnswerCard'

/*
 *
 * Page
 *
 */

const Page = (props) => {
  // props
  const { listingId } = props

  // state
  const [showAnswerQuestionModal, setShowAnswerQuestionModal] =
    React.useState(false)
  const [showEditQAModal, setShowEditQAModal] = React.useState(false)
  const [showDeleteModal, setShowDeleteModal] = React.useState(false)
  const [publishAnswerPublicly, setPublishAnswerPublicly] = React.useState(false)
  const [changePublishAnswerPublicly, setChangePublishAnswerPublicly] = React.useState(false)
  const [currentQuestionSelected, setCurrentQuestionSelected] = React.useState(false)
  const innerRef = useRef()
  // query
  const listingQuery = useGetListingQuery({ listingId, isVendor: true }) // N.B. from this page, we are always the vendor!
  const { data: { questions = [] } = {} } = listingQuery
  const questionsUnanswered = questions.filter(q => q.answer.trim() === '')
  const questionsAnsweredPrivate = questions.filter(q => q.answer.trim() !== '' && q.isPrivate)
  const questionsAnsweredPublic = questions.filter(q => q.answer.trim() !== '' && !q.isPrivate)
  const publishAnswerMutation = usePublishAnswerToQuestion()
  const deleteQuestion = useSellerDeleteQuestion()
  // methods
  const showAnswerModal = (show, selectedData) => {
    setShowAnswerQuestionModal(show)
    setCurrentQuestionSelected(selectedData)
  }

  const showEditModal = (selectedData) => {
    setChangePublishAnswerPublicly(!selectedData.isPrivate)
    setShowEditQAModal(true)
    setCurrentQuestionSelected(selectedData)
  }
  const publishAnswer = () => {
    publishAnswerMutation.mutate({
      listingId,
      questionId: currentQuestionSelected.id,
      newAnswer: {
        question: currentQuestionSelected.question,
        answer: currentQuestionSelected.answer,
        isPrivate: !publishAnswerPublicly
      }
    })

    setPublishAnswerPublicly(false)
    setShowAnswerQuestionModal(false)
  }
  const editAnswer = () => {
    publishAnswerMutation.mutate({
      listingId,
      questionId: currentQuestionSelected.id,
      newAnswer: {
        question: currentQuestionSelected.question,
        answer: currentQuestionSelected.answer,
        isPrivate: !changePublishAnswerPublicly
      }
    })
    setChangePublishAnswerPublicly(false)

    setShowEditQAModal(false)
  }
  const handleKeyDown = (e) => {
    e.target.style.height = 'inherit'
    e.target.style.height = `${e.target.scrollHeight + 8}px`
  }

  // confirm delete action
  const confirmDelete = () => {
    deleteQuestion.mutate({
      listingId,
      questionID: currentQuestionSelected.id
    })
    setShowDeleteModal(false)
  }
  return (
    <>
      <div className="card-body rounded-soft bg-white px-1 px-md-5 d-flex flex-column flex-grow-1 my-4">
        <div className="d-flex align-items-center justify-content-between mb-4  ">
          <span className=" inter-font-content-title">Listing Notes</span>
          <Button
            className=""
            variant="outline-primary"
            onClick={() => {
              vendorNavTo.editListingPage(listingId, {
                page: CREATE_LISTING_TAB_PAGES.supplements
              })
            }}
          >
            Edit
          </Button>
        </div>
        {listingQuery.isSuccess && (
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>Notes about the listing.</Form.Label>
                <div className="py-3 border p-3">
                  {listingQuery.isSuccess &&
                    listingQuery.data &&
                    typeof listingQuery.data.buyer_notes === 'string' &&
                    parse(listingQuery.data.buyer_notes)}
                </div>
              </Form.Group>
            </Col>
          </Row>
        )}
        <div className="mt-4 mb-3  d-flex align-items-center justify-content-between">
          <span className=" inter-font-content-title">Questions</span>
        </div>

        <Row>
          <Col>
            <Form.Group>
              <Form.Label>Questions submitted by participants invited to the listing.</Form.Label>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            <Accordion defaultActiveKey="0">
              <Card className="shadow-none border">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <img
                    src={arrowUpBoldSvg}
                    alt=""
                    width={18}
                    className="mr-2"
                  />
                  <NumberedRoundBage
                    questionLength={questionsUnanswered.length}
                  />
                  <b>Unanswered Questions</b>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="border-top">
                  {questionsUnanswered.length === 0
                    ? (
                    <Card.Body>No unanswered questions</Card.Body>
                      )
                    : <>
                        {questionsUnanswered.map((data, index) => {
                          return (
                            <QuestionAnswerCard
                              key={index}
                              data={data}
                              isBuyer={false}
                              showAnswerModal={showAnswerModal}
                              // deleteQuestion={deleteQuestion}
                              />
                          )
                        })}
                        </>
                      }
                </Accordion.Collapse>
              </Card>
            </Accordion>
            <Accordion defaultActiveKey="0">
              <Card className="shadow-none border">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <img
                    src={arrowUpBoldSvg}
                    alt=""
                    width={18}
                    className="mr-2"
                  />
                  <NumberedRoundBage questionLength={questionsAnsweredPrivate.length} />
                  <b>Private Questions</b>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="border-top">

                {publishAnswerMutation.isLoading || listingQuery.isFetching
                  ? <div className="d-flex justify-content-center p-5"><Spinner /></div>
                  : questionsAnsweredPrivate.length === 0
                    ? (
                    <Card.Body>No private questions
                    {publishAnswerMutation.isLoading && <div className="d-flex justify-content-center p-5"><Spinner /></div>}
                    </Card.Body>
                      )
                    : <>
                          {questionsAnsweredPrivate.map((data, index) => {
                            return (
                              <QuestionAnswerCard
                                key={index}
                                data={data}
                                isBuyer={false}
                                editButton={showEditModal}
                                isLoading={publishAnswerMutation.isLoading && data.id === currentQuestionSelected.id}
                                // deleteQuestion={deleteQuestion}
                                />
                            )
                          })}
                          </>

                    }
                </Accordion.Collapse>
              </Card>
            </Accordion>

            <Accordion defaultActiveKey="0">
              <Card className="shadow-none border">
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  <img
                    src={arrowUpBoldSvg}
                    alt=""
                    width={18}
                    className="mr-2"
                  />
                  <NumberedRoundBage questionLength={questionsAnsweredPublic.length} />
                  <b>Public Questions</b>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" className="border-top">
                {publishAnswerMutation.isLoading || listingQuery.isFetching
                  ? <div className="d-flex justify-content-center p-5"><Spinner /></div>
                  : questionsAnsweredPublic.length === 0
                    ? (
                    <Card.Body>No public questions</Card.Body>
                      )
                    : <>
                            {questionsAnsweredPublic.map((data, index) => {
                              return (
                                <QuestionAnswerCard
                                  key={index}
                                  data={data}
                                  isBuyer={false}
                                  editButton={showEditModal}
                                  // deleteQuestion={deleteQuestion}
                                  />
                              )
                            })}
                            </>
                  }
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Announcements isVendor={true} listingId={listingId} />
          </Col>
        </Row>

        {/* Answer question modal */}
        <Modal
          show={showAnswerQuestionModal}
          onHide={() => setShowAnswerQuestionModal(false)}
          onShow={() => { innerRef.current.focus() }}
        >
          <Modal.Body>
            <button
              type="button"
              className="close"
              onClick={() => setShowAnswerQuestionModal(false)}
            >
              <span aria-hidden="true">×</span>
              <span className="sr-only">Close</span>
            </button>
            <h5 className="m-0 ">Answer Question</h5>
            <div className="py-0">
              <Form.Group>
                <Row className="mt-4">
                  <Col sm={1}>
                    {' '}
                    <h3>Q</h3>
                  </Col>
                  <Col sm={11}>
                    {' '}
                    <b>{currentQuestionSelected.userWhoAsked}</b>{' '}
                    <Form.Control
                      type="text"
                      ref={innerRef}
                      as="textarea"
                      style={{ height: 100 + 'px', overflow: 'hidden' }}
                      onKeyDown={handleKeyDown}
                      onFocus={handleKeyDown} autoFocus
                      value={currentQuestionSelected.question}
                      className="mt-2"
                      onChange={(e) => { setCurrentQuestionSelected((ps) => ({ ...ps, question: e.target.value })) }}
                      placeholder=""
                    />
                  </Col>
                </Row>
                <Row className="my-4">
                  <Col sm={1}>
                    {' '}
                    <h3>A</h3>
                  </Col>
                  <Col sm={11}>
                    {' '}
                    <Form.Control
                      type="text"
                      as="textarea"
                      style={{ overflow: 'hidden' }}
                      value={currentQuestionSelected.answer}
                      className="mt-2"
                      onKeyDown={handleKeyDown}
                      onChange={(e) => { setCurrentQuestionSelected((ps) => ({ ...ps, answer: e.target.value })) }}
                      placeholder="Write your response here."
                    />
                  </Col>
                </Row>
                <Col
                  className={` mt-2  py-1 ${
                    publishAnswerPublicly ? 'highlighted-checkbox' : ''
                  }`}
                >
                  <Form.Check type="checkbox" id="cb-1">
                    <Form.Check.Input
                      type="checkbox"
                      checked={publishAnswerPublicly}
                      onChange={(e) => {
                        setPublishAnswerPublicly(e.target.checked)
                      }}
                    />
                    <Form.Check.Label className="cursor-pointer ml-2">
                      Publish answer publicly{' '}
                    </Form.Check.Label>
                  </Form.Check>
                </Col>
              </Form.Group>
            </div>
            <div className="d-flex justify-content-between mt-3">
              <Button
                type="button"
                onClick={() => setShowAnswerQuestionModal(false)}
                variant="outline-primary border-0"
              >
                Close
              </Button>
              <div>
                <Button variant="outline-primary mr-2 border-0" onClick={() => { setShowDeleteModal(true); setShowAnswerQuestionModal(false) }}> Delete Question</Button>
                <Button variant="primary" onClick={publishAnswer}>Publish Answer</Button>
              </div>
            </div>
          </Modal.Body>
          {process.env.NODE_ENV !== 'production' && (
            <pre>current ={JSON.stringify(currentQuestionSelected, null, 2)}</pre>
          )}
        </Modal>

        {/* Edit Q&A modal */}
        <Modal
        show={showEditQAModal}
        onHide={() => setShowEditQAModal(false)}
        onShow={() => { innerRef.current.focus() }}
      >
        <Modal.Body>
          <button
            type="button"
            className="close"
            onClick={() => setShowEditQAModal(false)}
          >
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <h5 className="m-0 ">Edit Question and Answer</h5>
          <div className="py-0">
            <Form.Group>
              <Row className="mt-4">
                <Col sm={1}>
                  {' '}
                  <h1>Q</h1>
                </Col>
                <Col sm={11}>
                  {' '}
                  <b>{currentQuestionSelected.userWhoAsked}</b>{' '}
                  <Form.Control
                    type="text"
                    ref={innerRef}
                    as="textarea"
                    style={{ height: 100 + 'px', overflow: 'hidden' }}
                    onKeyDown={handleKeyDown}
                    onFocus={handleKeyDown} autoFocus
                    value={currentQuestionSelected.question}
                    className="mt-2"
                    onChange={(e) => { setCurrentQuestionSelected((ps) => ({ ...ps, question: e.target.value })) }}
                    placeholder=""
                  />
                </Col>
              </Row>
              <Row className="my-4">
                <Col sm={1}>
                  {' '}
                  <h1>A</h1>
                </Col>
                <Col sm={11}>
                  {' '}
                  <Form.Control
                    type="text"
                    value={currentQuestionSelected.answer}
                    className="mt-2"
                    as="textarea"
                    style={{ overflow: 'hidden' }}
                    onKeyDown={handleKeyDown}
                    onChange={(e) => { setCurrentQuestionSelected((ps) => ({ ...ps, answer: e.target.value })) }}
                    placeholder="Write your response here."
                  />
                </Col>
              </Row>
              <Col
                className={` mt-2  py-1 ${
                  changePublishAnswerPublicly ? 'highlighted-checkbox' : ''
                }`}
              >
                <Form.Check type="checkbox" id="cb-1">
                  <Form.Check.Input
                    type="checkbox"
                    checked={changePublishAnswerPublicly}
                    onChange={(e) => {
                      setChangePublishAnswerPublicly(e.target.checked)
                    }}
                  />
                  <Form.Check.Label className="cursor-pointer ml-2">
                    Publish answer publicly{' '}
                  </Form.Check.Label>
                </Form.Check>
              </Col>
            </Form.Group>
          </div>
          <div className="d-flex justify-content-between mt-3">
            <Button
              type="button"
              onClick={() => setShowEditQAModal(false)}
              variant="outline-primary border-0"
            >
              Close
            </Button>
            <div>
              <Button variant="outline-primary mr-2 border-0" onClick={() => { setShowDeleteModal(true); setShowEditQAModal(false) }}> Delete Question</Button>
              <Button variant="primary" onClick={editAnswer}>Save Changes</Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
      <Modal.Body>
          <h5 className="m-0 mb-3">Delete question?</h5>
          <div className="py-3">
            This cannot be undone.
          </div>
          <div className="d-flex justify-content-between mt-5">
              <Button type="button" onClick={() => setShowDeleteModal(false)} variant="outline-primary">Close</Button>
              <Button type="button" onClick={() => { confirmDelete(); setShowDeleteModal(false) }} variant="primary">Delete</Button>
          </div>
      </Modal.Body>
  </Modal>

        {process.env.NODE_ENV !== 'production' && (
          <>
          <pre>questionId ={JSON.stringify(currentQuestionSelected, null, 2)}</pre>
          <pre>listingQuery ={JSON.stringify(listingQuery.data, null, 2)}</pre>
          </>
        )}
      </div>
    </>
  )
}

export default Page
