
module.exports = {
  CREATE_LISTING_TAB_PAGES: {
    details: 0,
    supplements: 1,
    productmanifest: 2,
    newrecords: 3,
    participants: 4
  }
}
