/* eslint-disable camelcase */

import React from 'react'
import { useGetListingQuery } from '../../../../components/api'
import { SimpleTabs } from '../../../../components/Form'
import Layout from '../../../../components/Layout'
import ListingStatus from '../../../../components/ListingStatus'
import VendorCommunicationsTab from '../../../../components/viewListingTabs/vendorCommunicationsTab'

const Page = (props) => {
  const { listingId } = props
  const listingQuery = useGetListingQuery({ listingId, isVendor: true }) // N.B. from this page, we are always the buyer!
  const { data: { global_listing_number = '' } = {} } = listingQuery

  // react-state
  const [page, setPage] = React.useState(0)

  return <Layout>

        <Layout.Title showBackButton>
            <div className="d-flex align-items-center">
              <h5>List Number: {global_listing_number}</h5>
              { listingQuery.isSuccess && <ListingStatus status={listingQuery.data.status} /> }
            </div>
        </Layout.Title>

        <Layout.CardGrey>

            <SimpleTabs elements={[
              { label: 'Communications', onClick: () => setPage(0) }
            ]} selectedIndex={page} size="md">

            </SimpleTabs>
            {/* Buyer */}
            <VendorCommunicationsTab {...props}></VendorCommunicationsTab>
        </Layout.CardGrey>

    </Layout>
}

export default Page
